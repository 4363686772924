interface ConvertToPascalCaseOptions {
    lowercaseForRest?: boolean,
    firstWordOnly?: boolean
};

export const convertToPascalCase = (str : string, options : ConvertToPascalCaseOptions = { }) : string => {
    if(!str) {
        return str;
    }
    const atoms = str.split(' ');
    if(atoms.length > 1) {
        return atoms.map((o, i) => (options.firstWordOnly ?? false) && i > 0 ? o : convertToPascalCase(o, options)).join(' ');
    }
    return str.substring(0, 1).toUpperCase() + (str.length > 1 ? ((options.lowercaseForRest ?? false) || isAllUppercase(str) ? str.substring(1).toLowerCase() : str.substring(1)) : '');
};

export const isAllUppercase = (str : string) : boolean => {
    for(let i=0; i< str.length; i++) {
        if(str.charAt(i) !== str.charAt(i).toUpperCase()) {
            return false;
        }
    }
    return true;
};

export const compareStrings = (a : string | undefined, b : string | undefined) : number => {
    if(a === undefined && b === undefined) {
        return 0;
    }
    if(a === undefined) {
        return 1;
    }
    if(b === undefined) {
        return -1;
    }
    if(a === b) {
        return 0;
    }
    if(a < b) {
        return -1;
    }
    return 1;
};