import Area from '../models/Area';
import { getResource } from '../resources/resource-manager';
import format from 'format-number';
import AreaMetadata from '../models/AreaMetadata';
import LayerGroup from '../models/LayerGroup';
import { convertToPascalCase, isAllUppercase } from './string-helper';
import { formatDate } from '../logic/date-helper';

export const createMetadataContent = (areas : Area[], layerGroups : LayerGroup[] | undefined, index: number) => {
    const area = areas[index];
    const layerGroup = layerGroups?.find(lg => lg.key === area.layerGroupKey);

    let title = getResource(`layerGroups.${area.layerGroupKey}`);
    if(area.layerKey !== area.layerGroupKey) {
        title += ', ' + getResource(`layers.${area.layerKey}`)
    }
    const titleMarkup = `<div class="metadata-popup-title-container"><div class="metadata-popup-layer-group-legend" style="background-color: ${formatColor(layerGroup?.color)};"></div><div class="metadata-popup-title">${title}</div></div>`
    let tableMarkup = '<table class="metadata-table">';
    area.metadatas.forEach((metadata : any) => {
        tableMarkup += `<tr><th>${getResource(`metadataTitles.${area.layerKey}.${metadata.key}`, true)}</th><td>${formatMetadataValue(metadata, area)}</td></tr>`;
    });
    tableMarkup += '</table>';

    return '<div class="metadata-content">' + titleMarkup + tableMarkup + '</div>';
};

export const addNavigationEvents = (popup : L.Popup, areas : Area[], layerGroups : LayerGroup[] | undefined) => {
    const popupElement = popup.getElement();
    const previousButton = popupElement?.querySelector('.navigation-button-previous');
    if(previousButton) {
        previousButton.addEventListener('click', () => handlePreviousButtonClicked(popup, areas, layerGroups));
    }
    const nextButton = popupElement?.querySelector('.navigation-button-next');
    if(nextButton) {
        nextButton.addEventListener('click', () => handleNextButtonClicked(popup, areas, layerGroups));
    }
};

const handlePreviousButtonClicked = (popup : L.Popup, areas : Area[], layerGroups : LayerGroup[] | undefined) => {
    const currentIndex = getCurrentIndex(popup);
    const newIndex = (currentIndex + areas.length - 1) % areas.length;
    setCurrentIndex(popup, areas, layerGroups, newIndex);
};

const handleNextButtonClicked = (popup : L.Popup, areas : Area[], layerGroups : LayerGroup[] | undefined) => {
    const currentIndex = getCurrentIndex(popup);
    const newIndex = (currentIndex + 1) % areas.length;
    setCurrentIndex(popup, areas, layerGroups, newIndex);
};

const getCurrentIndex = (popup : L.Popup) : number => {
    const popupElement = popup.getElement();
    const indexAttribute = popupElement?.querySelector('.metadata-popup')?.getAttribute('data-index');
    return parseInt(indexAttribute ?? '0');
};

const setCurrentIndex = (popup : L.Popup, areas : Area[], layerGroups : LayerGroup[] | undefined, index : number) => {
    const popupElement = popup.getElement();
    popupElement?.querySelector('.metadata-popup')?.setAttribute('data-index', index + '');
    const newContent = createMetadataContent(areas, layerGroups,  index);
    const tmpDiv = document.createElement('div');
    tmpDiv.innerHTML = newContent;
    const container = popupElement?.querySelector('.metadata-content-container');
    container?.replaceChildren(tmpDiv.firstChild!);
    popupElement!.querySelector('.navigation-label')!.innerHTML = (index + 1) + '/' + areas.length;
};

const areaKeys = [ 'AREA_HA', 'AreaTot', 'SHAPE_AREA', 'LAND_HA', 'Hektar', 'TOTAL_AREA', 'AREAL_HA', 'Areal', 'AnmaldHa', 'Natforha' ];
const dateKeys = [ 'ArendeAr', 'Avvdatum', 'DATSTART', 'DatAvtal', 'Datinv', 'InkomDatum', 'Inkomdatum', 'Invdat', 'R', 'SCI_DATUM', 'URSBESLDAT', 'URSPBESDAT' ];
const geographicalNameKeys = [ 'KOMMNAMN', 'KOMMUN', 'Kommun', 'LAN', 'LANNAMN', 'Lan' ];

const formatMetadataValue = (metadata : AreaMetadata, area : Area) => {
    if(areaKeys.find(o => o === metadata.key)) {
        const formatter = format({
            integerSeparator: getResource('thousandsSeparator'),
            decimal: getResource('decimalPoint'),
            round: 1
        });
        let areaValue = parseFloat(metadata.value);
        if(areaValue === 0) {
            // fallback to area of polygon
            areaValue = area.area / (100 * 100);
        }
        return formatter(areaValue) + ' ' + getResource('hectaresAbbreviated');
    }
    if(dateKeys.find(o => o === metadata.key)) {
        if(metadata.value.length === 6) { //yyyymm
            return `${metadata.value.substring(0, 4)}-${metadata.value.substring(4, 6)}-01`;
        }
        return formatDate(metadata.value);
    }
    if(geographicalNameKeys.find(o => o === metadata.key)) {
        return convertUppercaseGeographicalName(metadata.value);
    }

    return metadata.value;
};

const formatColor = (color : string | undefined) : string | undefined => {
    if(!color) {
        return color;
    }
    var hashStrippedColor = color.substring(0, 1) === "#"
        ? color.substring(1)
        : color;
    if(hashStrippedColor.length === 8) {
        return `#${hashStrippedColor.substring(2)}`;
    }
    if(hashStrippedColor.length === 4) {
        return `#${hashStrippedColor.substring(1)}`;
    }
    return `#${hashStrippedColor}`;
};

const convertUppercaseGeographicalName = (name : string) : string => {
    const atoms = name.split('-').join(' ').split(' ');

    let isAllPascalCase = true;
    for(let i=0; i<atoms.length; i++) {
        if(atoms[i].length > 0 && atoms[i].charAt(0) !== atoms[i].charAt(0).toUpperCase()) {
            isAllPascalCase = false;
            break;
        }
    }

    if(!isAllUppercase(name) && !isAllPascalCase) {
        return name;
    }

    for(let i=0; i<atoms.length; i++) {
        atoms[i] = convertToPascalCase(atoms[i], { lowercaseForRest: true });
        if(atoms[i] === "Kommun" || atoms[i] === "Län") {
            atoms[i] = atoms[i].toLowerCase();
        }
    }

    const convertedName = atoms.join(' ');
    const charsToReturn : string[] = [];
    for(let i=0; i < convertedName.length; i++) {
        if(name.charAt(i) === '-') {
            charsToReturn.push('-');
        } else {
            charsToReturn.push(convertedName.charAt(i));
        } 
    }
    return charsToReturn.join('');
};
