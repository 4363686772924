import React, { useEffect, useState } from 'react';
import { Space, Form, Button, Typography } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import LoadingSpinner from '../LoadingSpinner';
import WmsConsumer from '../../models/WmsConsumer';
import WmsConsumerFormFields from './WmsConsumerFormFields';
import ConfirmationModal from '../../components/ConfirmationModal';
import { Link } from "react-router-dom";
import { getWmsConsumer, updateWmsConsumer, deleteWmsConsumer } from '../../api/wms-consumers';

const { Title } = Typography;

const EditWmsConsumer = () => {
    const { username } = useParams();
    const navigate = useNavigate();
    const [ wmsConsumer, setWmsConsumer ] = useState<WmsConsumer | undefined>(undefined);
    const [ isLoading, setIsLoading ] = useState(true);
    const [ isSaving, setIsSaving ] = useState(false);
    const [ deleteConfirmationModalOpen, setDeleteConfirmationModalOpen ] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setWmsConsumer(await getWmsConsumer(username!));
            setIsLoading(false);
        };

        fetchData();
    }, [username]);

    const handleSubmit = async (wmsConsumerToSave : WmsConsumer) => {
        setIsSaving(true);
        await updateWmsConsumer(username!, wmsConsumerToSave);
        navigate('/admin/wms-consumers');
    };

    const handleDelete = async () => {
        setDeleteConfirmationModalOpen(true);
    };

    const handleDeleteConfirmed = async () => {
        setIsSaving(true);
        await deleteWmsConsumer(username!);
        navigate('/admin/wms-consumers');
    };

    const handleDeleteCanceled = async () => {
        setDeleteConfirmationModalOpen(false);
    };

    if(isLoading || isSaving) {
        return <LoadingSpinner/>;
    }

    return (
        <Space direction="vertical">
            <Title level={2}>Edit WMS consumer</Title>
           
            <Form
                labelCol={{ span: 8 }}
                style={{ maxWidth: 600 }}            
                initialValues={wmsConsumer}
                onFinish={handleSubmit}
                autoComplete="off"
            >
                <WmsConsumerFormFields showPassword />

                <Form.Item wrapperCol={{ offset: 8 }}> 
                    <Space>
                        <Button type="primary" htmlType="submit">
                            Update
                        </Button>
                        <Button type="default" danger onClick={handleDelete}>
                            Delete
                        </Button>
                        <Link to="/admin/wms-consumers">
                            <Button type="default">
                                Cancel
                            </Button>
                        </Link>
                    </Space>
                </Form.Item>
            </Form>

            <ConfirmationModal
                open={deleteConfirmationModalOpen}
                title="Confirm removal"
                body={`Do you really want to delete the user with username '${username}'?`}
                onConfirm={handleDeleteConfirmed}
                onCancel={handleDeleteCanceled}
                type="danger"
                confirmLabel="Yes, delete"
                cancelLabel="No, cancel"
            />
        </Space>
    );
};

export default EditWmsConsumer;
