import React, { useEffect, useRef } from 'react';
import { Modal, Input, Button,InputRef } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';
import { getResource } from '../resources/resource-manager';

import './ShareModal.css';

type ShareModalProps = {
    open: boolean;
    onClose: () => void;
};

const ShareModal = ({ open, onClose } : ShareModalProps) => {
    const url = window.location.href;
    const inputRef = useRef<InputRef>(null);

    useEffect(() => {
        if (open && inputRef?.current) {
            inputRef.current.select();
            setTimeout(() => {
                inputRef.current?.focus();
            }, 0);
        }
    }, [open]);

    const handleClick = () => {
        navigator.clipboard.writeText(url);
    };

    return (
        <Modal
            title={getResource('shareModalTitle')}
            open={open}
            footer={null}
            className="share-modal"
            width="90%"
            onCancel={onClose}
        >
            <div className="url-container">
                <Input value={url} onChange={() => {}} ref={inputRef} />
                <Button size="small" icon={<FontAwesomeIcon icon={faClipboard} />} onClick={handleClick}/>
            </div>
        </Modal>
    );
};

export default ShareModal;
