import React from 'react';
import { Dropdown, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { getResource } from '../resources/resource-manager';
import KeyedLeafletLayer from '../models/KeyedLeafletLayer';

type BackgroundMapSelectorProps = {
    selectedKey: string, 
    backgroundMapLayers: KeyedLeafletLayer[],
    onChange: (key: string) => void
};
const BackgroundMapSelector = ({ selectedKey, backgroundMapLayers, onChange } : BackgroundMapSelectorProps) => {
    const handleButtonClick : MenuProps['onClick'] = (e) => {
        onChange(e.key);
    };

    const items : MenuProps['items'] = backgroundMapLayers
        .map(backgroundMapLayer => ({
            label: getResource(`layerGroups.${backgroundMapLayer.key}`),
            key: backgroundMapLayer.key
        }));

    const menuProps = {
        items,
        onClick: handleButtonClick
    };

    return (
        <Dropdown menu={menuProps}>
            <Button block>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <span>
                        {getResource(`layerGroups.${selectedKey}`)}
                    </span>
                    <DownOutlined />
                </div>
            </Button>
        </Dropdown>
    );
};

export default BackgroundMapSelector;
