import React from 'react';
import { Layout, Space, Typography } from 'antd';

const { Title, Paragraph } = Typography;
const { Content } = Layout;

const WmsGuide = () => {
    return (
        <Layout>
            <Content 
                style={{
                        padding: 24,
                        minHeight: '100vh',
                        maxWidth: '800px',
                        margin: '0 auto'
                    }}
            >
                <Space direction="vertical">
                    <Title level={2}>Skogsmonitor: WMS guide</Title>

                    <Paragraph>
                        Skogsmonitor provides a <a href="https://en.wikipedia.org/wiki/Web_Map_Service" target="_blank" rel="noreferrer">WMS</a> (Web Map Service) endpoint for use of our data in external applications such as GIS software.
                        The endpoint address is <code className="nowrap">https://api.skogsmonitor.se/wms</code>.
                        The layers 'Old forests &amp; continuity forests' and 'Logged old forests &amp; continuity forests' are available.
                    </Paragraph>

                    <Paragraph>
                        The WMS is a paid subscription-based service. Contact us at <a href="mailto:skogsmonitor@skyddaskogen.se">skogsmonitor@skyddaskogen.se</a> for pricing and access.
                    </Paragraph>

                    <Paragraph>
                        The <a href="https://en.wikipedia.org/wiki/Basic_access_authentication" target="_blank" rel="noreferrer">Basic Authentication</a> method is used to secure the service.
                        Most GIS software allows the user to enter a Basic Authentication username and password when connecting to a WMS.
                    </Paragraph>

                    <Paragraph>
                        When connecting in another way, include an HTTP header in the format <code className="nowrap">Authorization: Basic {'{'}base64(username:password){'}'}</code> in your requests.
                        As an example, for username <code>user</code> and password <code>secret</code>, the HTTP header would be <code className="nowrap">Authorization: Basic dXNlcjpzZWNyZXQ=</code>.
                        {' '}
                        The <a href="https://www.debugbear.com/basic-auth-header-generator" target="_blank" rel="noreferrer">DebugBear website</a> will help you create an Authorization header from a username and password.
                    </Paragraph>
                </Space>
            </Content>
        </Layout>
    );
};

export default WmsGuide;
