import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';

type BooleanIconProps = {
    value: boolean;
};

const BooleanIcon = ({ value } : BooleanIconProps) => {
    return value
        ? <FontAwesomeIcon icon={faCircleCheck} className="boolean-icon boolean-icon-true" />
        : <FontAwesomeIcon icon={faCircleXmark} className="boolean-icon boolean-icon-false" />;
};

export default BooleanIcon;
