import React, { useState } from 'react';
import { Dropdown, Button } from 'antd';
import type { MenuProps } from 'antd';
import Place from '../models/Place';
import Area from '../models/Area';
import PlaceSearchModal from './PlaceSearchModal';
import NotifiedLoggingAreaSearchModal from './NotifiedLoggingAreaSearchModal';
import { getResource } from '../resources/resource-manager';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type SearchButtonProps = {
    onPlaceSelected: (place: Place | undefined) => void;
    onNotifiedLoggingAreaSelected: (area: Area | undefined) => void;
};

const SearchButton = ({ onPlaceSelected, onNotifiedLoggingAreaSelected } : SearchButtonProps) => {
    const [searchPlaceModalOpen, setSearchPlaceModalOpen] = useState<boolean>(false);
    const [searchNotifiedLoggingAreaModalOpen, setSearchNotifiedLoggingAreaModalOpen] = useState<boolean>(false);
    
    const handlePlaceSelected = (place : Place | undefined) => {
        setSearchPlaceModalOpen(false);
        onPlaceSelected(place);
    };

    const handleSearchPlaceModalClose = () => {
        setSearchPlaceModalOpen(false);
    };

    const handleSearchPlaceButtonClick = () => {
        setSearchPlaceModalOpen(true);
    };

    const handleNotifiedLoggingAreaSelected = (area : Area | undefined) => {
        setSearchNotifiedLoggingAreaModalOpen(false);
        onNotifiedLoggingAreaSelected(area);
    };

    const handleSearchNotifiedLoggingAreaModalClose = () => {
        setSearchNotifiedLoggingAreaModalOpen(false);
    };

    const handleSearchNotifiedLoggingAreaButtonClick = () => {
        setSearchNotifiedLoggingAreaModalOpen(true);
    };

    const items : MenuProps['items'] = [
        {
          key: '1',
          label: (
            <Button type="link" size="small" onClick={handleSearchPlaceButtonClick}>
                {getResource("toolbar.searchPlace")}
            </Button>
          )
        },
        {
          key: '2',
          label: (
            <Button type="link" size="small" onClick={handleSearchNotifiedLoggingAreaButtonClick}>
                {getResource("toolbar.searchNotifiedLoggingArea")}
            </Button>
          )
        }
    ];    

    return (
        <>
            <div>
                <Dropdown menu={{ items }} placement="bottom" arrow={{ pointAtCenter: true }}>
                    <Button size="small" className="toolbar-button toolbar-dropdown-button" title={getResource('toolbar.search')}>
                        <FontAwesomeIcon icon={faSearch} />
                    </Button>
                </Dropdown>
                <PlaceSearchModal
                    open={searchPlaceModalOpen}
                    onPlaceSelected={handlePlaceSelected}
                    onClose={handleSearchPlaceModalClose}
                />
                <NotifiedLoggingAreaSearchModal
                    open={searchNotifiedLoggingAreaModalOpen}
                    onNotifiedLoggingAreaSelected={handleNotifiedLoggingAreaSelected}
                    onClose={handleSearchNotifiedLoggingAreaModalClose}
                />
            </div>
        </>
    );
};

export default SearchButton;
