import { MapQuerystringParameters } from '../logic/map-helper';
import Layer from './Layer';

export default interface LayerGroup {
    // from backend
    layerGroupId: number;
    key: string;
    guid: string;
    color: string;
    minimumZoomLevel: number;
    maximumZoomLevel: number;
    updatedTime: Date;
    layers: Layer[];
    zIndex: number;
    opacity: number;
    type: string;
    wmsUrl: string | undefined;
    wmsParameters: string | undefined;
    internalUse: boolean;

    // added by frontend
    visible: boolean;
};

export const enhanceLayerGroups = (layerGroups : LayerGroup[], mapQuerystringParameters : MapQuerystringParameters | undefined) => {
    if(!mapQuerystringParameters) {
        return;
    }
    const visibleLayerGroupIds = mapQuerystringParameters.layers
        ?.split('-').map(o => parseInt(o));

    layerGroups!.forEach(layerGroup => {
        if(visibleLayerGroupIds) {
            layerGroup.visible = !!visibleLayerGroupIds.find(o => o === layerGroup.layerGroupId);
        }
    });
};
