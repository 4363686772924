import React from 'react';
import { Checkbox, Form, Input } from 'antd';

type WmsConsumerFormFieldsProps = {
    showPassword?: boolean;
};

type FieldType = {
    name: string;
    description: string | undefined;
    username: string;
    password: string | undefined;
    accessEnabled: boolean;
};

const { TextArea } = Input;

const WmsConsumerFormFields = ({ showPassword = false } : WmsConsumerFormFieldsProps) => {
    return (
        <>
            <Form.Item<FieldType>
                label="Name"
                name="name"
                rules={[{ required: true }]}
            >
                <Input />
            </Form.Item>

            <Form.Item<FieldType>
                label="Description"
                name="description"
            >
                <TextArea rows={3} />
            </Form.Item>
            
            <Form.Item<FieldType>
                label="Username"
                name="username"
                help="The username that the WMS consumer provides when authenticating."
                rules={[{ required: true }]}
            >
                <Input />
            </Form.Item>

            {
                showPassword &&
                (
                    <Form.Item<FieldType>
                        label="Password"
                        name="password"
                        help="The password that the WMS consumer provides when authenticating."
                    >
                        <Input disabled />
                    </Form.Item>
                )
            }
        
            <Form.Item<FieldType>
                label="Enable access"
                name="accessEnabled"
                valuePropName="checked"
            >
                <Checkbox />
            </Form.Item>
        </>
    );
};

export default WmsConsumerFormFields;
