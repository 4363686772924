import React, { useState } from 'react';
import { ConfigProvider } from 'antd';
import { Slider } from 'antd';
import { getResource } from '../resources/resource-manager';

type OpacitySliderProps = {
    initialValue: number,
    color: string,
    className?: string,
    onChange: (value: number) => void
};

const OpacitySlider = ({ initialValue, color, className, onChange } : OpacitySliderProps) => {
    const [value, setValue] = useState<number>(initialValue);

    return (
        <div className={className} title={getResource('opacity')}>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: color
                    },
                }}
            >
                <Slider
                    min={0}
                    max={100}
                    tooltip={{ formatter: null }}
                    value={100 * value}
                    onChange={newValue => {
                        setValue(newValue / 100);
                        onChange(newValue / 100);
                    }}
                />
            </ConfigProvider>
        </div>
    );
};

export default OpacitySlider;
