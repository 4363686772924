import { getApiBaseUrl } from './api-settings';

export const searchArtdatabankenObservations = async (request : any) : Promise<any> => {
    const response = await fetch(
        `${getApiBaseUrl()}/artdatabanken/observations`,
        {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(request)
          }
    );
    return await response.json();
};
