import React, { useState } from 'react';
import { Switch, ConfigProvider, Modal } from 'antd';
import LayerGroup from '../models/LayerGroup';
import { getResource } from '../resources/resource-manager';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import KeyedLeafletLayer from '../models/KeyedLeafletLayer';
import OpacitySlider from './OpacitySlider';

type LayerGroupListProps = {
    layerGroups: LayerGroup[],
    leafletLayers: KeyedLeafletLayer[],
    handleLayerGroupClick: (layerGroup: LayerGroup) => void
};

const getOpaqueColor = (color : string | undefined) : (string | undefined) => {
    if(color) {
        return "#" + color.substring(3);
    }
    return undefined;
};

const LayerGroupList = ({ layerGroups, leafletLayers, handleLayerGroupClick } : LayerGroupListProps) => {
    const [layerGroupDescriptionModalOpen, setLayerGroupDescriptionModalOpen] = useState<boolean>(false);
    const [selectedLayerGroup, setSelectedLayerGroup] = useState<LayerGroup | undefined>();

    const handleTooltipIconClick = (layerGroup : LayerGroup) => {
        setSelectedLayerGroup(layerGroup);
        setLayerGroupDescriptionModalOpen(true);
    };

    const handleCloseLayerGroupDescriptionModal = () => {
        setLayerGroupDescriptionModalOpen(false);
        setSelectedLayerGroup(undefined);
    };

    const getSubtitle = (layerGroup : LayerGroup) => {
        const subtitleContent = getResource(`layerGroups.${layerGroup.key}.subtitle`, true /* returnUndefinedIfNotFound */);
        if(!subtitleContent || !layerGroup.visible) {
            return null;
        }
        return (
            <div 
                className="layer-group-subtitle"
                dangerouslySetInnerHTML={{ __html: subtitleContent }}
            />
        );
    };

    const getClassificationDataLeafletLayer = (layerGroupKey : string) => leafletLayers.find(o => o.key === layerGroupKey)?.layer;

    const getOpacitySlider = (layerGroup : LayerGroup) => {
        if((layerGroup.key !== 'RemainingClassificationData' && layerGroup.key !== 'ExecutedLoggingClassificationData') || !layerGroup.visible) {
            return null;
        }
        
        return (
            <OpacitySlider
                initialValue={layerGroup.opacity}    
                color="#df4b83"
                className="opacity-slider-container"
                onChange={newValue => {
                    const remainingClassificationDataLeafletLayer = getClassificationDataLeafletLayer(layerGroup.key);
                    if(remainingClassificationDataLeafletLayer) {
                        remainingClassificationDataLeafletLayer.setOpacity(newValue);
                    }
                }}
            />
        );
    };

    return (
        <>
            <ul id="layer-group-list">
                {
                    layerGroups
                        .filter(layerGroup => !layerGroup.internalUse || layerGroup.visible)
                        .map(layerGroup => (
                            <li key={layerGroup.key}>
                                <div>
                                    <ConfigProvider
                                        theme={{
                                            token: {
                                                colorPrimary: getOpaqueColor(layerGroup.color) ?? '#333',
                                            },
                                        }}
                                    >
                                        <Switch
                                            size="small"
                                            checked={layerGroup.visible}
                                            onClick={() => handleLayerGroupClick(layerGroup)}
                                        />
                                    </ConfigProvider>
                                    <span className="layer-group-name">
                                        {getResource(`layerGroups.${layerGroup.key}`)}
                                    </span>
                                    <span className="layer-group-information-icon">
                                        <FontAwesomeIcon
                                            icon={faInfoCircle}
                                            onClick={() => handleTooltipIconClick(layerGroup)}
                                        />
                                    </span>
                                </div>
                                {
                                    getSubtitle(layerGroup)
                                }
                                {
                                    getOpacitySlider(layerGroup)
                                }
                            </li>
                        ))
                }
            </ul>
            <Modal
                title={getResource('layerDescriptionModalTitle') + ': ' + getResource(`layerGroups.${selectedLayerGroup?.key}`)}
                open={layerGroupDescriptionModalOpen}
                footer={null}
                className="layer-group-description-modal"
                width="90%"
                onCancel={handleCloseLayerGroupDescriptionModal}
            >
                <ul>
                    {
                        selectedLayerGroup?.layers.map(layer => (
                            <li key={layer.key}>
                                {
                                    selectedLayerGroup?.layers.length > 1 &&
                                    (
                                        <h4>
                                            {getResource(`layers.${layer.key}`)}
                                        </h4>
                                    )
                                }
                                <p dangerouslySetInnerHTML={{ __html: getResource(`layerDescriptions.${layer.key}`)! }}/>
                            </li>
                        ))
                    }
                </ul>
            </Modal>
        </>
    );
};

export default LayerGroupList;
