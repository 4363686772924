import L from 'leaflet';

// see https://github.com/jaq316/leaflet-header/blob/master/index.js

const fetchImage = async (url: string, callback: (resp: any) => void, headers: { header: string, value: string }[]) => {
    let _headers : any = {};
    if (headers) {
        headers.forEach(h => {
            _headers[h.header] = h.value;
        });
    }
  
    const f = await fetch(url, {
        method: "GET",
        headers: _headers,
        mode: "cors"
    });
    const blob = await f.blob();
    callback(blob);
};

const TileLayerWithHeaders = L.TileLayer.extend({
    initialize: function (url: string, options: any, headers: { header: string, value: string }[]) {
        (L.TileLayer.prototype as any).initialize.call(this, url, options);
        this.headers = headers;
    },
    createTile(coords: any, done: any) {
        const url = this.getTileUrl(coords);
        const img = document.createElement("img");
        img.setAttribute("role", "presentation");

        fetchImage(
            url,
            resp => {
                const reader = new FileReader();
                reader.onload = () => {
                    img.src = reader.result as string;
                };
                reader.readAsDataURL(resp);
                done(null, img);
            },
            this.headers
        );
        return img;
    }
});

export const tileLayerWithHeaders = (url: string, options: any, headers: { header: string, value: string }[]) => {
    return new (TileLayerWithHeaders as any)(url, options, headers);
};
