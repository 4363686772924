import { getApiBaseUrl } from "./api-settings";
import WmsConsumer from '../models/WmsConsumer';
import { addAdminPasswordHeader } from './api-helper';

export const getWmsConsumers = async () : Promise<WmsConsumer[]> => {
    const response = await fetch(
        `${getApiBaseUrl()}/wms-consumers`,
        {
            method: 'GET',
            headers: addAdminPasswordHeader() 
        }
    );
    return await response.json();
};

export const getWmsConsumer = async (username : string) : Promise<WmsConsumer> => {
    const response = await fetch(
        `${getApiBaseUrl()}/wms-consumers/${encodeURIComponent(username)}`, 
        {
            method: 'GET',
            headers: addAdminPasswordHeader() 
        }
    );
    return await response.json();
};

export const createWmsConsumer = async (wmsConsumer : WmsConsumer) : Promise<WmsConsumer> => {
    const response = await fetch(
        `${getApiBaseUrl()}/wms-consumers`,
        {
            method: 'POST',
            headers: addAdminPasswordHeader({
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify(wmsConsumer)
        }
    );
    return await response.json();
};

export const updateWmsConsumer = async (username : string, wmsConsumer : WmsConsumer) : Promise<WmsConsumer> => {
    const response = await fetch(
        `${getApiBaseUrl()}/wms-consumers/${encodeURIComponent(username)}`,
        {
            method: 'PUT',
            headers: addAdminPasswordHeader({
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify(wmsConsumer)
        }
    );
    return await response.json();
};

export const deleteWmsConsumer = async (username : string) : Promise<void> => {
    await fetch(
        `${getApiBaseUrl()}/wms-consumers/${encodeURIComponent(username)}`,
        {
            method: 'DELETE',
            headers: addAdminPasswordHeader()
        }
    );
};