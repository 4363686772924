import sv from './sv';
import en from './en';
import store from 'store2';

export const getResource = (key: string, returnUndefinedIfNotFound : boolean | undefined = false) : string | undefined => {
    const language = store('language');
    var prioritizedLanguages = language === 'sv'
        ? [sv, en]
        : [en, sv];
    
    for(let i = 0; i < prioritizedLanguages.length; i++) {
        const resource = (prioritizedLanguages[i] as any)[key];
        if(resource) {
            return resource;
        }
    }

    return returnUndefinedIfNotFound
        ? undefined
        : `[${key}]`;
};