import React, { ReactNode } from 'react';
import { Modal } from 'antd';

type InformationModalProps = {
    open: boolean;
    onClose: () => void;
    className?: string | undefined;
    title?: string | undefined;
    children: ReactNode | undefined;
};

const InformationModal = ({ open, onClose, className, title, children } : InformationModalProps) => {
    return (
        <Modal
            title={title}
            open={open}
            onCancel={onClose}
            footer={null}
            className={`information-modal ${className}`}
        >
            {children}
        </Modal>
    );
};

export default InformationModal;
