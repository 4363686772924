import ExportToExcelRequest from '../models/ExportToExcelRequest';
import { getApiBaseUrl } from './api-settings';

export const exportToExcel = async (request : ExportToExcelRequest) : Promise<any> => {
    return await fetch(
        `${getApiBaseUrl()}/excel`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request)
          }
    );
};