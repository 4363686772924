import { getApiBaseUrl } from './api-settings';
import Area from '../models/Area';

export const getAreasForLocation = async (latitude: number, longitude: number) : Promise<Area[]> => {
    const response = await fetch(`${getApiBaseUrl()}/areas?latitude=${latitude}&longitude=${longitude}`);
    const areas = await response.json();
    return areas;
};

export const getAreasByMetadata = async (layerKey: string, metadataKey: string, metadataValue: string) : Promise<Area[]> => {
    const response = await fetch(`${getApiBaseUrl()}/areas/metadata/${encodeURIComponent(layerKey)}/${encodeURIComponent(metadataKey)}/${encodeURIComponent(metadataValue)}`);
    if(response.status === 404) {
        return [];
    }
    const areas = await response.json();
    return areas;
};
