import React, { useState, useEffect } from 'react';
import { Alert, Space, Modal, Table, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faSpinner, faRotateLeft, faClose, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { getResource } from '../resources/resource-manager';
import type { ColumnsType } from 'antd/es/table';
import { convertToPascalCase, compareStrings } from '../logic/string-helper';
import { exportToExcel } from '../api/excel';

import './ArtdatabankenObservationsModal.css';
import ExportToExcelRequest from '../models/ExportToExcelRequest';
import { downloadFile } from '../logic/browser-helper';

type ArtdatabankenObservationsModalProps = {
    observations: any[];
    open: boolean;
    onTaxonFilter: (taxonId: number | undefined, onFilterApplied: () => void) => void;
    onClose: () => void;
};

interface Taxon {
    index: number;
    vernacularName: string;
    scientificName: string;
    organismGroup: string;
    redlistCategory?: string;
    redlistCategorySortOrder: number;
    taxonId: number;
    count: number;
    key: any;
};

const ArtdatabankenObservationsModal = ({ observations, open, onTaxonFilter, onClose } : ArtdatabankenObservationsModalProps) => {
    const [ screenSize, setScreenSize ] = useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight
    });

    const isSmallScreen = screenSize.dynamicWidth < 600;

    const [ filteringTaxonIds, setFilteringTaxonIds ] = useState<number[]>([]);
    const [ isFiltered, setIsFiltered ] = useState<boolean>(false);
    
    const columns: ColumnsType<Taxon> = isSmallScreen
        ? [
            {
                title: getResource('artdatabanken.name'),
                render: (_, record) => <>{record.vernacularName}<br/><span className="small"><em>{record.scientificName}</em></span><br/><span className="small">{record.organismGroup}</span></>,
                sorter: (a, b) => compareStrings(a.vernacularName, b.vernacularName)
            },
            {
                title: getResource('artdatabanken.redlistCategoryAbbreviated'),
                render: (_, record) => record.redlistCategory,
                sorter: (a, b) => compareStrings(a.redlistCategory, b.redlistCategory)
            },
            {
                title: getResource('artdatabanken.countAbbreviated'),
                render: (_, record) => record.count,
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.count - b.count,
                align: 'right'
            }
        ]
        : [
            {
                title: getResource('artdatabanken.vernacularName'),
                render: (_, record) => record.vernacularName,
                sorter: (a, b) => compareStrings(a.vernacularName, b.vernacularName)
            },
            {
                title: getResource('artdatabanken.scientificName'),
                render: (_, record) => <em>{record.scientificName}</em>,
                sorter: (a, b) => compareStrings(a.scientificName, b.scientificName)
            },
            {
                title: getResource('artdatabanken.organismGroup'),
                render: (_, record) => record.organismGroup,
                sorter: (a, b) => compareStrings(a.organismGroup, b.organismGroup)
            },
            {
                title: getResource('artdatabanken.redlistCategory'),
                render: (_, record) => record.redlistCategory,
                sorter: (a, b) => a.redlistCategorySortOrder - b.redlistCategorySortOrder
            },
            {
                title: getResource('artdatabanken.count'),
                render: (_, record) => record.count,
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.count - b.count,
                align: 'right'
            },
            {
                render: (_, record) => <Button size="small" icon={getFilterIcon(record)} disabled={filteringTaxonIds.length > 0} onClick={() => handleFilterButtonClick(record)}/>,
                align: 'right'
            }
        ];

    const [ sortByColumn, setSortByColumn ] = useState<any>(columns.find(o => o.defaultSortOrder));
    const [ sortOrder, setSortOrder ] = useState<any>(columns.find(o => o.defaultSortOrder)?.defaultSortOrder);

    const handleResize = () => {
        setScreenSize({
            dynamicWidth: window.innerWidth,
            dynamicHeight: window.innerHeight
        });
    };
      
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return(() => {
            window.removeEventListener('resize', handleResize);
        })
    }, [screenSize]);

    const getFilterIcon = (record : Taxon) => filteringTaxonIds.includes(record.taxonId)
        ? <FontAwesomeIcon icon={faSpinner} spinPulse />
        : <FontAwesomeIcon icon={faFilter} />;

    const taxonDictionary : { [name: string]: any } = {};

    observations.forEach(observation => {
        taxonDictionary[observation.taxon.scientificName] = taxonDictionary[observation.taxon.scientificName] as any 
            ?? 
            { 
                scientificName: observation.taxon.scientificName,
                vernacularName: convertToPascalCase(observation.taxon.vernacularName, { firstWordOnly: true }),
                taxonId: observation.taxon.id,
                organismGroup: observation.taxon.attributes.organismGroup,
                redlistCategory: observation.taxon.attributes.redlistCategory,
                redlistCategorySortOrder: observation.taxon.attributes.redlistCategorySortOrder,
                count: 0,
                key: observation.taxon.scientificName
            };
        taxonDictionary[observation.taxon.scientificName].count++;
    });

    const taxons : Taxon[] = Object.values(taxonDictionary);

    let index = 0;
    observations.forEach(taxons => {
        taxons.index = index++;
    });

    const flatten = (obj : any) => {
        if(typeof obj === 'object') {
            return (obj as any).props.children;
        }
        return obj;
    };

    const handleFilterButtonClick = (record : Taxon) => {
        setFilteringTaxonIds([record.taxonId]);
        onTaxonFilter(record.taxonId, () => {
            setFilteringTaxonIds([]);
            setIsFiltered(true);            
        });
    };

    const handleResetFilterButtonClick = () => {
        setFilteringTaxonIds([]);
        onTaxonFilter(undefined, () => {
            setIsFiltered(false);            
        });
    };

    const handleExportToExcelButtonClick = async () => {
        const sortedTaxons = [...taxons];
        sortedTaxons.sort(sortByColumn?.sorter ?? (() => 0));
        if(sortOrder === 'descend') {
            sortedTaxons.reverse();
        }
        
        const request : ExportToExcelRequest = {
            columns: columns
                .filter(c => c.title)
                .map(c => ({ title: c.title as any })),
            rows: sortedTaxons.map(taxon => ({
                cells: columns
                    .filter(c => c.title)
                    .map((c, i) => flatten(c.render!(undefined, taxon, i)))
            }))
        };

        exportToExcel(request)
            .then(response => response.blob())
            .then(blob => downloadFile(blob, 'species.xlsx'));
    };

    const handleTableChange = (pagination: any, filters: any, sorter: any, extra : any) => {
        setSortByColumn(sorter.column);
        setSortOrder(sorter.order);
    };

    const footer = (
        <>
            {
                isFiltered &&
                (
                    <Button
                        onClick={handleResetFilterButtonClick}
                        icon={<FontAwesomeIcon icon={faRotateLeft} />}
                    >
                        <span className="icon-button-text">{getResource('artdatabanken.resetFilter')}</span>
                    </Button>
                )
            }
            <Button
                onClick={handleExportToExcelButtonClick}
                icon={<FontAwesomeIcon icon={faFileExcel} />}
            >
                <span className="icon-button-text">{getResource('artdatabanken.exportToExcel')}</span>
            </Button>
            <Button
                onClick={onClose}
                icon={<FontAwesomeIcon icon={faClose} />}
            >
                <span className="icon-button-text">{getResource('close')}</span>
            </Button>
        </>
    );

    return (
        <Modal
            title={getResource('artdatabanken.observationsModalTitle')}
            className="artdatabanken-observations-modal"
            open={open}
            footer={footer}
            onCancel={onClose}
        >
            <Space direction="vertical" style={{ width: '100%' }}>
                {
                    observations.length >= 1000 && 
                    (
                        <Alert
                            message={getResource('artdatabanken.maxObservationsInformation')}
                            type="warning"
                        />
                    )
                }
                <Table
                    columns={columns}
                    dataSource={taxons}
                    size="small"
                    pagination={{ defaultPageSize: isSmallScreen ? 5 : 20 }}
                    onChange={handleTableChange}
                />
            </Space>
        </Modal>
    );
};

export default ArtdatabankenObservationsModal;
