import { DrawHandlers, DrawToolbar, EditHandlers, EditToolbar } from "../index";

const drawToolbar: DrawToolbar = {
  actions: {
    title: "Avbryt markering",
    text: "Avbryt",
  },
  finish: {
    title: "Slutför markering",
    text: "Slutför",
  },
  undo: {
    title: "Ta bort sista punkten",
    text: "Ta bort sista punkten",
  },
  buttons: {
    polyline: "Rita flera linjer",
    polygon: "Rita en polygon",
    rectangle: "Rita en rektangel",
    circle: "Rita en cirkel",
    marker: "Rita en markeringspunkt",
    circlemarker: "Rita en cirkelpunkt",
    label: "Rita en etikett"
  },
};

const drawHandlers: DrawHandlers = {
  circle: {
    tooltip: {
      start: "Klicka och dra för att rita en cirkel.",
    },
    radius: "Radie",
  },
  circlemarker: {
    tooltip: {
      start: "Klicka på kartan för att placera en cirkelpunkt.",
    },
  },
  label: {
    tooltip: {
      start: "Klicka på kartan för att placera en etikett.",
    },
  },
  marker: {
    tooltip: {
      start: "Klicka på kartan för att markera en punkt.",
    },
  },
  polygon: {
    tooltip: {
      start: "Klicka på kartan för att rita ett område.",
      cont: "Klicka för att forsätta rita.",
      end: "Klicka på den första punktenför att slutföra området.",
    },
  },
  polyline: {
    error: "<strong>Fel:</strong> kanterna får inte korsas!",
    tooltip: {
      start: "Klicka för att rita en linje.",
      cont: "Klicka för att fortsätta rita linjen.",
      end: "Klicka på sen sista punkten för att slutföra linjen.",
    },
  },
  rectangle: {
    tooltip: {
      start: "Klicka och dra för att rita en riktangel.",
    },
  },
  simpleshape: {
    tooltip: {
      end: "Släpp upp musknappen för att slutföra ritningen.",
    },
  },
};

const editToolbar: EditToolbar = {
  actions: {
    save: {
      title: "Spara ändringar.",
      text: "Spara",
    },
    cancel: {
      title: "Avbryt redigering og förkasta ändringar.",
      text: "Avbryt",
    },
    clearAll: {
      title: "Nollställ alla figurer.",
      text: "Nollställ allt",
    },
  },
  buttons: {
    edit: "Redigera figur",
    editDisabled: "Det finns ingen figur att redigera.",
    remove: "Ta bort figur",
    removeDisabled: "Det finns ingen figur att ta bort.",
  },
};

const editHandlers: EditHandlers = {
  edit: {
    tooltip: {
      text: "Dra markören för att redigera.",
      subtext: "Klicka Avbryt för att ångra ändringar.",
    },
  },
  remove: {
    tooltip: {
      text: "Klicka för att ta bort",
    },
  },
};

const sv = {
  draw: {
    toolbar: drawToolbar,
    handlers: drawHandlers,
  },
  edit: {
    toolbar: editToolbar,
    handlers: editHandlers,
  },
};

export default sv;