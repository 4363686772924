import React, { useState } from 'react';
import L from 'leaflet';
import AppContext from '../models/AppContext';
import GeographicalPosition from './GeographicalPosition';
import ShareModal from './ShareModal';
import DownloadMapModal, { DownloadMapFormValues } from './DownloadMapModal';
import ToolbarButton from './ToolbarButton';
import SearchButton from './SearchButton';
import { faShareNodes, faDownload, faBars, faLocationCrosshairs, faSeedling } from '@fortawesome/free-solid-svg-icons';
import LanguageSelector from './LanguageSelector';
import { getResource } from '../resources/resource-manager';
import Place from '../models/Place';
import Area from '../models/Area';
import { exportMapImage } from '../api/maps';
import { downloadFile } from '../logic/browser-helper';
import { getLeafletCanvasLayerAsDataUrl } from '../leaflet/leaflet-helper';

import './Toolbar.css';

type ToolbarProps = {
    map?: L.Map,
    appContext: AppContext,
    hoverPosition?: L.LatLng,
    geolocationEnabled: boolean,
    onMenuButtonClick: () => void,
    onLanguageChange: (language : string) => void,
    onSearchArtdatabankenObservations: () => void,
    onAreaSelected: (area : Area | undefined) => void
};

const Toolbar = ({ map, appContext, hoverPosition, geolocationEnabled, onMenuButtonClick, onLanguageChange, onSearchArtdatabankenObservations, onAreaSelected } : ToolbarProps) => {
    const [ shareModalOpen, setShareModalOpen ] = useState<boolean>(false);
    const [ downloadMapModalOpen, setDownloadMapModalOpen ] = useState<boolean>(false);
    const [ isDownloadingMap, setIsDownloadingMap ] = useState<boolean>(false);
    const handleShareButtonClick = () => {
        setShareModalOpen(true);
    };
    const handleShareModalClose = () => {
        setShareModalOpen(false);
    };
    
    const handleDownloadButtonClick = () => {
        if(map) {
            setIsDownloadingMap(false);
            setDownloadMapModalOpen(true);
        }
    };

    const handleDownloadMapModalOk = (values : DownloadMapFormValues) => {
        setIsDownloadingMap(true);
        getLeafletCanvasLayerAsDataUrl(
            map!,
            (dataUrl : string | undefined) => {
                exportMapImage(getExportMapImageRequest(values, dataUrl))
                    .then(response => response.blob())
                    .then(blob => {
                        downloadFile(blob, `skogsmonitor.${values.format}`);
                        setIsDownloadingMap(false);
                        setDownloadMapModalOpen(false);
                    });
            }
        );
    };

    const handleDownloadMapModalClose = () => {
        setDownloadMapModalOpen(false);
    };

    const handlePanToCurrentLocationButtonClick = () => {
        const geolocationPosition = appContext.geolocationPosition;
        if(map && geolocationPosition) {
            map.setView([geolocationPosition.coords.latitude, geolocationPosition.coords.longitude], Math.max(13, map.getZoom()));
        }
    };

    const handlePlaceSelected = (place : Place | undefined) => {
        if(place && map) {
            map.setView([place.location.latitude, place.location.longitude], Math.max(13, map.getZoom()));
        }
    };

    const handleNotifiedLoggingAreaSelected = (area : Area | undefined) => onAreaSelected(area);

    const getExportMapImageRequest = (values : DownloadMapFormValues, dataUrl: string | undefined) => {
        const bounds = map!.getBounds();
        const backgroundMapLayer = appContext.backgroundMapLayers?.find(o => o.key === appContext.selectedBackgroundMapLayerKey)!;
        return {
            format: values.format,
            zoomLevel: map!.getZoom(),
            boundingBox: {
                south: bounds.getSouth(),
                north: bounds.getNorth(),
                west: bounds.getWest(),
                east: bounds.getEast()
            },
            boundingBoxEpsgCode: 4326,
            backgroundMapLayer: {
                key: backgroundMapLayer.key,
                opacity: backgroundMapLayer.layer.options.opacity ?? 1
            },
            layerGroups: appContext.layerGroups
                ?.filter(layerGroup => layerGroup.visible)
                .map(layerGroup => ({
                    key: layerGroup.key,
                    opacity: layerGroup.opacity
                })),
            canvasLayers: dataUrl
                ? [dataUrl]
                : undefined,
            attribution: backgroundMapLayer.layer.getAttribution
                ? backgroundMapLayer.layer.getAttribution()
                : undefined
        };
    };

    const logotypeLinkUrl = appContext.language === 'sv'
        ? 'https://www.skogsmonitor.se'
        : 'https://www.skogsmonitor.se/en';

    return (
        <>
            <div id="toolbar" className="no-print">
                <a href={logotypeLinkUrl}>
                    <img id="logotype" src="/assets/skogsmonitor.png" alt="Skogsmonitor" />
                </a>
                <ToolbarButton icon={faShareNodes} onClick={handleShareButtonClick} tooltip={getResource('toolbar.share')} />
                <ToolbarButton icon={faDownload} onClick={handleDownloadButtonClick} tooltip={getResource('toolbar.download')} />
                { geolocationEnabled && <ToolbarButton icon={faLocationCrosshairs} onClick={handlePanToCurrentLocationButtonClick} tooltip={getResource('toolbar.location')} /> }
                <ToolbarButton icon={faSeedling} onClick={onSearchArtdatabankenObservations} tooltip={getResource('toolbar.species')} />
                <SearchButton onPlaceSelected={handlePlaceSelected} onNotifiedLoggingAreaSelected={handleNotifiedLoggingAreaSelected}/>
                <div className="separator"></div>
                <GeographicalPosition position={hoverPosition}/>
                <LanguageSelector
                    language={appContext.language}
                    onChange={onLanguageChange}
                />
                <ToolbarButton mobileOnly icon={faBars} onClick={onMenuButtonClick} />
            </div>
            <ShareModal
                open={shareModalOpen}
                onClose={handleShareModalClose}
            />
            <DownloadMapModal
                open={downloadMapModalOpen}
                isLoading={isDownloadingMap}
                onOk={handleDownloadMapModalOk}
                onClose={handleDownloadMapModalClose}
            />
        </>
    );
};

export default Toolbar;
