import React, { useState, useEffect, useRef } from 'react';
import { Modal, AutoComplete } from 'antd';
import type { BaseSelectRef } from 'rc-select';
import Place from '../models/Place';
import { getPlaces } from '../api/places';
import { getResource } from '../resources/resource-manager';

import './PlaceSearchModal.css';

type PlaceSearchModalProps = {
    open: boolean;
    onPlaceSelected: (place: Place | undefined) => void;
    onClose: () => void;
};

const PlaceSearchModal = ({ open, onClose, onPlaceSelected } : PlaceSearchModalProps) => {
    const [options, setOptions] = useState<{ value: string }[]>([]);
    const [places, setPlaces] = useState<Place[]>([]);
    const [value, setValue] = useState<string>('');
    const autoCompleteRef = useRef<BaseSelectRef>(null);
    
    // fetch places from backend
    useEffect(() => {
        if(open) {
            const fetchData = async () => {
                const placeFeatureCollection = await getPlaces();
                setPlaces(placeFeatureCollection.features.map((feature : any) => ({
                    name: feature.properties.name,
                    location: {
                        latitude: feature.geometry.coordinates[1],
                        longitude: feature.geometry.coordinates[0]
                    }
                })));
                setOptions(placeFeatureCollection.features.map((feature : any) => ({
                    value: feature.properties.name
                })));
            };
    
            fetchData().catch(console.error);
        }
    }, [open]);

    useEffect(() => {
        setTimeout(() => {
            if (open && autoCompleteRef?.current) {
                autoCompleteRef.current.focus();
            }
        }, 0);
    }, [open]);

    const handleSearch = (text: string) => {
        setValue(text);
    };

    const handleSelect = (value: string) => {
        onPlaceSelected(places.find(o => o.name.toLowerCase() === value.toLowerCase()));
        setValue('');
    };

    const handleBlur = () => {
        onPlaceSelected(places.find(o => o.name.toLowerCase() === value.toLowerCase()));
        setValue('');
    };

    return (
        <Modal
            title={getResource('toolbar.searchPlace')}
            open={open}
            footer={null}
            onCancel={onClose}
        >
            <AutoComplete
                ref={autoCompleteRef}
                className="place-textbox"
                value={value}
                options={options}
                style={{ width: '100%' }}
                onSearch={handleSearch}
                onSelect={handleSelect}
                onBlur={handleBlur}
                filterOption
            />
        </Modal>
    );
};

export default PlaceSearchModal;
