import queryString from 'query-string';

export const setUrl = (querystringParameters : Record<string, any>) => {
    const qs = queryString.stringify(querystringParameters);
    let url = qs
        ? '/?' + qs
        : '';
    window.history.replaceState(null, '', url);
};

export const downloadFile = (blob : Blob, fileName : string) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click();    
    a.remove();  //afterwards we remove the element again       
};