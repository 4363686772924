import React, { useEffect, useState } from 'react';
import { Space, Menu, Table, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import type { MenuProps } from 'antd';
import LoadingSpinner from '../LoadingSpinner';
import WmsConsumer from '../../models/WmsConsumer';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import BooleanIcon from '../../components/BooleanIcon';
import { getWmsConsumers } from '../../api/wms-consumers';
import { compareStrings } from '../../logic/string-helper';

type MenuItem = Required<MenuProps>['items'][number];

const { Title } = Typography;

const menuItems: MenuItem[] = [
    {
        icon: <FontAwesomeIcon icon={faAdd} />,
        key: 'CreateWmsConsumer',
        label: <Link to="/admin/create-wms-consumer">Create</Link>
    },
];

const WmsConsumers = () => {
    const [ wmsConsumers, setWmsConsumers ] = useState<WmsConsumer[] | undefined>(undefined);
    const [ isLoading, setIsLoading ] = useState(true);
    
    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            setWmsConsumers(await getWmsConsumers());
            setIsLoading(false);
        };

        fetchData();
    }, []);

    const columns: ColumnsType<WmsConsumer> = [
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: (a, b) => compareStrings(a.name, b.name)
        },
        {
            key: 'username',
            title: 'Username',
            render: value => <Link to={encodeURIComponent(value.username)}>{value.username}</Link>,
            sorter: (a, b) => compareStrings(a.username, b.username)
        },
        {
            key: 'accessEnabled',
            title: 'Access enabled',
            render: value => <BooleanIcon value={value.accessEnabled}/>,
            sorter: (a, b) => compareStrings(a.username, b.username)
        }
    ];

    if(isLoading) {
        return <LoadingSpinner/>;
    }

    return (
        <Space direction="vertical">
            <Title level={2}>WMS consumers</Title>
            <Menu mode="horizontal" items={menuItems} />
            <Table
                columns={columns}
                dataSource={wmsConsumers}
                rowKey="username"
                pagination={false}
            />
        </Space>
    );
};

export default WmsConsumers;
