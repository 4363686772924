import L from 'leaflet';

/**
 * @class L.Draw.Label
 * @aka Draw.Label
 * @inherits L.Draw.Marker
 */
L.Draw.Label = L.Draw.Marker.extend({
	statics: {
		TYPE: 'label'
	},

	options: {
		color: '#3388ff',
		opacity: 0.5,
		clickable: true,
		zIndexOffset: 2000 // This should be > than the highest z-index any markers
	},

	// @method initialize(): void
	initialize: function (map, options) {
		// Save the type so super can fire, need to do this as cannot do this.TYPE :(
		this.type = L.Draw.Label.TYPE;

		this._initialLabelText = L.drawLocal.draw.handlers.label.tooltip.start;

		L.Draw.Feature.prototype.initialize.call(this, map, options);
	},

	_fireCreatedEvent: function () {
		this._marker.setIcon(createLabelIcon(''));
		L.Draw.Feature.prototype._fireCreatedEvent.call(this, this._marker);
	},

	_createMarker: function (latlng) {
		var marker = new L.Marker(latlng, {
			icon: createLabelIcon('')
		});
		marker.setText = function(text) {
			marker.setIcon(createLabelIcon(text));
		};

		return marker;
	}

});

const createLabelIcon = function(text) {
	const canvas = document.createElement('canvas');
	const textSize = measureText(text);

	canvas.width = textSize.width + 16;
	canvas.height = textSize.fontBoundingBoxAscent + textSize.fontBoundingBoxDescent + 16;
	const x = canvas.width / 2;
	const y = canvas.height / 2;
	const ctx = canvas.getContext('2d');
	setFontProperties(ctx);
	ctx.textAlign = 'center';
	ctx.textBaseline = 'middle';
	ctx.strokeStyle = 'rgba(255, 255, 255, 0.85)';
	ctx.lineWidth = 4;
	ctx.strokeText(text, x, y);
	ctx.strokeStyle = 'black';
	ctx.lineWidth = 1;
	ctx.strokeText(text, x, y);
	ctx.fillStyle = 'black';
	ctx.fillText(text, x, y);
	const dataUrl = canvas.toDataURL();

	return new L.Icon({
		iconUrl: dataUrl,
		iconSize: L.point(canvas.width, canvas.height)
	})
};

const measureText = function(text) {
	const canvas = document.createElement('canvas');
	const ctx = canvas.getContext('2d');
	setFontProperties(ctx);
	return ctx.measureText(text);
};

const setFontProperties = function(ctx) {
	ctx.font = '20px Arial';
};
