import React from 'react';
import { Dropdown, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { getResource } from '../resources/resource-manager';

type LanguageSelectorProps = {
    language: string, 
    onChange: (language: string) => void
};
const LanguageSelector = ({ language, onChange } : LanguageSelectorProps) => {
    const handleButtonClick : MenuProps['onClick'] = (e) => {
        onChange(e.key);
    };

    const items : MenuProps['items'] = ['sv', 'en']
        .map(language => ({
            label: getResource(`languages.${language}`),
            key: language
        }));

    const menuProps = {
        items,
        onClick: handleButtonClick
    };
    
    return (
        <>
            <div className="mobile-hidden">
                <Dropdown menu={menuProps}>
                    <Button size='small'>
                        <span>
                            {getResource(`languages.${language}`)}
                        </span>
                        <DownOutlined />
                    </Button>
                </Dropdown>
            </div>
            <div className="mobile-visible">
                <Dropdown menu={menuProps}>
                    <Button size='small'>
                        <span>
                            {getResource(`languages.${language}.abbreviated`)}
                        </span>
                        <DownOutlined />
                    </Button>
                </Dropdown>
            </div>
        </>
    );
};

export default LanguageSelector;
