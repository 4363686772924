import L from 'leaflet';

// SVG map over Sweden in Web Mercator projection downloaded from https://www.amcharts.com/svg-maps/?map=sweden
// it was then converted to a PNG of size 557 x 1260 px
const overviewMapWebMercatorBounds = {
    sw: { x: 1220417, y: 7425224 },
    ne: { x: 2688944, y: 10769438 }
};

const originalImageWidth = 557;
const originalImageHeight = 1260;

const originalScaleX = originalImageWidth / (overviewMapWebMercatorBounds.ne.x - overviewMapWebMercatorBounds.sw.x);
const originalScaleY = originalImageHeight / (overviewMapWebMercatorBounds.ne.y - overviewMapWebMercatorBounds.sw.y);

L.Control.OverviewMap = L.Control.extend({
    onAdd: function () {
        const container = L.DomUtil.create('div');
        container.className = 'overview-map';

        const inner = L.DomUtil.create('div');
        inner.className = 'overview-map-inner';

        const img = L.DomUtil.create('img');
        img.src = '/assets/sweden-overview-map.png';

        const currentBounds = L.DomUtil.create('div');
        currentBounds.className = 'current-bounds';
        
        const currentPoint = L.DomUtil.create('div');
        currentPoint.className = 'current-point';

        inner.appendChild(img);
        inner.appendChild(currentBounds);

        container.appendChild(inner);

        return container;
    },

    onRemove: function () {
    },

    setBounds: function (bounds) {
        const container = this.getContainer();
        const inner = container.querySelector('.overview-map-inner');
        const currentBounds = container.querySelector('.current-bounds');
        const imageWidth = inner.offsetWidth;
        const imageHeight = imageWidth * (originalImageHeight / originalImageWidth);

        // convert WGS84 to Web Mercator
        const sw = bounds.getSouthWest();
        const ne = bounds.getNorthEast();
        const webMercatorSw = L.Projection.SphericalMercator.project(sw);
        const webMercatorNe = L.Projection.SphericalMercator.project(ne);

        // convert Web Mercator to pixels in overview map image
        const scaleX = (imageWidth / originalImageWidth) * originalScaleX;
        const scaleY = (imageHeight / originalImageHeight) * originalScaleY;
        const imageSw = {
            x: (webMercatorSw.x - overviewMapWebMercatorBounds.sw.x) * scaleX,
            y: (overviewMapWebMercatorBounds.ne.y - webMercatorSw.y) * scaleY
        };
        const imageNe = {
            x: (webMercatorNe.x - overviewMapWebMercatorBounds.sw.x) * scaleX,
            y: (overviewMapWebMercatorBounds.ne.y - webMercatorNe.y) * scaleY
        };
        let widthInPixels = imageNe.x - imageSw.x;
        let heightInPixels = imageSw.y - imageNe.y;
        let leftInPixels;
        let topInPixels;
        const minSizeInPixels = 6;
        if(widthInPixels < minSizeInPixels && heightInPixels < minSizeInPixels) {
            // the bounds rectangle shouldn't be too small
            const centerX = imageSw.x + widthInPixels / 2;
            const centerY = imageNe.y + heightInPixels / 2;
            const scale = widthInPixels < heightInPixels
                ? minSizeInPixels / heightInPixels
                : minSizeInPixels / widthInPixels;
            widthInPixels *= scale;
            heightInPixels *= scale;
            leftInPixels = centerX - widthInPixels / 2;
            topInPixels = centerY - heightInPixels / 2;
        } else {
            leftInPixels = imageSw.x;
            topInPixels = imageNe.y;
        }
        currentBounds.style.left = leftInPixels + 'px';
        currentBounds.style.width = widthInPixels + 'px';
        currentBounds.style.top = topInPixels + 'px';
        currentBounds.style.height = heightInPixels + 'px';
    }
});

L.control.overviewMap = options => {
    return new L.Control.OverviewMap(options);
};
