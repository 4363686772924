import React, { useState } from 'react';
import LayerGroup from '../models/LayerGroup';
import KeyedLeafletLayer from '../models/KeyedLeafletLayer';
import BackgroundMapSelector from './BackgroundMapSelector';
import LayerGroupList from './LayerGroupList';
import { getResource } from '../resources/resource-manager';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OpacitySlider from './OpacitySlider';

import './Menu.css';

type MenuProps = {
    selectedBackgroundMapLayerKey: string,
    layerGroups: LayerGroup[],
    backgroundMapLayers: KeyedLeafletLayer[],
    leafletLayers: KeyedLeafletLayer[],
    onLayerGroupVisibilityChange: (layerGroup: LayerGroup) => void,
    onSelectedBackgroundMapLayerKeyChange: (backgroundMapLayerKey: string) => void,
};

const Menu = ({ selectedBackgroundMapLayerKey, layerGroups, backgroundMapLayers, leafletLayers, onLayerGroupVisibilityChange, onSelectedBackgroundMapLayerKeyChange } : MenuProps) => {
    const [showBackgroundMapOpacitySlider, setShowBackgroundMapOpacitySlider] = useState<boolean>(false);

    const handleLayerGroupClick = (layerGroup: LayerGroup) => {
        onLayerGroupVisibilityChange(layerGroup);
    };

    const handleSettingsButtonClick = () => {
        setShowBackgroundMapOpacitySlider(!showBackgroundMapOpacitySlider);
    };

    return (
        <div id="menu">
            <div id="background-map-selector-container">
                <div className="small-label">
                    {getResource('backgroundMap')}
                </div>
                <div className="background-map-selector-row">
                    <BackgroundMapSelector
                        selectedKey={selectedBackgroundMapLayerKey}
                        backgroundMapLayers={backgroundMapLayers}
                        onChange={onSelectedBackgroundMapLayerKeyChange}
                    />
                    <Button
                        size="small"
                        onClick={handleSettingsButtonClick}
                        icon={<FontAwesomeIcon icon={faCog} />}
                        title={getResource('backgroundMapSettings')}
                    />
                </div>
                {
                    showBackgroundMapOpacitySlider &&
                    (
                        <OpacitySlider
                            initialValue={100}    
                            color="#5E7F7F"
                            onChange={newValue => {
                                backgroundMapLayers.forEach(bml => {
                                    bml.layer.setOpacity(newValue);
                                });
                            }}
                        />
                    )
                }
            </div>
            <div id="layer-group-list-container">
                <div className="small-label">
                    {getResource('layers')}
                </div>
                <LayerGroupList 
                    layerGroups={layerGroups}
                    leafletLayers={leafletLayers}
                    handleLayerGroupClick={handleLayerGroupClick}
                />
            </div>
        </div>
    );
};

export default Menu;
