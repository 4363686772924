import React from 'react';
import { Modal, Spin, Form, Radio, Button, Space } from 'antd';
import { getResource } from '../resources/resource-manager';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faClose } from '@fortawesome/free-solid-svg-icons';

import './DownloadMapModal.css';

type DownloadMapModalProps = {
    open: boolean;
    isLoading: boolean;
    onOk: (values : DownloadMapFormValues) => void,
    onClose: () => void
};

export type DownloadMapFormValues = {
    format: string;
};

const initialValues = {
    format: 'tiff'
};

const DownloadMapModal = ({ open, onOk, onClose, isLoading } : DownloadMapModalProps) => {
    const [ form ] = Form.useForm();

    const handleOk = () => {
        onOk({
            format: form.getFieldValue('format')
        });
    };

    const footer = (
        <Space size="middle">
            {
                isLoading && <Spin/>
            }
            <div>
                <Button
                    onClick={handleOk}
                    disabled={isLoading}
                    icon={<FontAwesomeIcon icon={faDownload} />}
                >
                    <span className="icon-button-text">{getResource('downloadMapModal.download')}</span>
                </Button>
                <Button
                    onClick={onClose}
                    icon={<FontAwesomeIcon icon={faClose} />}
                >
                    <span className="icon-button-text">{getResource('close')}</span>
                </Button>
            </div>
        </Space>
    );

    return (
        <Modal
            title={getResource('downloadMapModal.title')}
            open={open}
            onCancel={onClose}
            footer={footer}
            closable
            className="download-map-modal"
        >
            <Space direction="vertical">
                <div>{getResource('downloadMapModal.description')}</div>
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={initialValues}
                    disabled={isLoading}
                >
                    <Form.Item name="format">
                        <Radio.Group>
                            <Space direction="vertical">
                                <Radio value="tiff">{getResource('downloadMapModal.tiff')}</Radio>
                                <Radio value="pdf">{getResource('downloadMapModal.pdf')}</Radio>
                                <Radio value="png">{getResource('downloadMapModal.png')}</Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                </Form>
            </Space>
        </Modal>
    );
};

export default DownloadMapModal;
