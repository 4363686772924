import React, { useState, useRef, useEffect, ChangeEvent } from 'react';
import { Modal, Input, Space, InputRef,  } from 'antd';
import Area from '../models/Area';
import { getAreasByMetadata } from '../api/areas';
import { getResource } from '../resources/resource-manager';

import './NotifiedLoggingAreaSearchModal.css';

type NotifiedLoggingAreaSearchModalProps = {
    open: boolean;
    onNotifiedLoggingAreaSelected: (area: Area | undefined) => void;
    onClose: () => void;
};

const NotifiedLoggingAreaSearchModal = ({ open, onClose, onNotifiedLoggingAreaSelected } : NotifiedLoggingAreaSearchModalProps) => {
    const [ text, setText ] = useState<string>('');
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ matchingAreas, setMatchingAreas ] = useState<Area[] | undefined>(undefined);
    const inputRef = useRef<InputRef>(null);
    
    useEffect(() => {
        if(open) {
            setText('');
            setLoading(false);
            setMatchingAreas(undefined);
            setTimeout(() => {
                inputRef.current!.focus({
                    cursor: 'end'
                });
            }, 0);
        }
    }, [open]);

    const handleTextChange = (event : ChangeEvent<HTMLInputElement>) => {
        setText(event.target.value);
    };

    const handleSearch = async () => {
        setLoading(true);
        const areas = (await getAreasByMetadata('NotifiedLoggingLast42Days', 'Beteckn', text))
            .concat(await getAreasByMetadata('NotifiedLoggingOlderThan42Days', 'Beteckn', text));
        setLoading(false);
        if(areas.length > 0) {
            onNotifiedLoggingAreaSelected(areas[0]);
        } else {
            setMatchingAreas(areas);
        }
    };

    return (
        <Modal
            title={getResource('toolbar.searchNotifiedLoggingArea')}
            open={open}
            footer={null}
            onCancel={onClose}
        >
            <Space direction="vertical" size={16} className="modal-inner">
                <Input.Search ref={inputRef} value={text} placeholder={getResource('notifiedLoggingAreaSearchModal.identity')} onChange={handleTextChange} onSearch={handleSearch} enterButton={getResource('toolbar.search')} loading={loading} />

                {
                    (matchingAreas && matchingAreas.length === 0) &&
                    (
                        <div>{getResource('notifiedLoggingAreaSearchModal.noMatches')}</div>
                    )
                }
            </Space>
        </Modal>
    );
};

export default NotifiedLoggingAreaSearchModal;
