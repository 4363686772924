import React, { useState, useRef, useEffect, ChangeEvent, KeyboardEvent } from 'react';
import { Modal, Input, InputRef } from 'antd';
import { getResource } from '../resources/resource-manager';

// import './LabelModal.css';

type LabelModalProps = {
    open: boolean;
    onOk: (text : string) => void;
    onCancel: () => void;
};

const LabelModal = ({ open, onOk, onCancel } : LabelModalProps) => {
    const [ text, setText ] = useState<string>('');
    const inputRef = useRef<InputRef>(null);
    
    const handleTextChange = (event : ChangeEvent<HTMLInputElement>) => {
        setText(event.target.value);
    };

    const handleTextKeyDown = (event : KeyboardEvent<HTMLInputElement>) => {
        if(event.key === 'Enter') {
            onOk(text);    
        }
    };

    const handleOk = () => {
        onOk(text);
    };

    useEffect(() => {
        if(open) {
            setText('');
            setTimeout(() => {
                inputRef.current!.focus({
                    cursor: 'end'
                });
            }, 0);
        }
    }, [open]);

    return (
        <Modal
            title={getResource('labelModalTitle')}
            open={open}
            className="label-modal"
            onOk={handleOk}
            onCancel={onCancel}
            okText={getResource('ok')}
            cancelText={getResource('cancel')}
        >
            <Input ref={inputRef} value={text} onChange={handleTextChange} onKeyDown={handleTextKeyDown} />
        </Modal>
    );
};

export default LabelModal;
