import React, { useState } from 'react';
import { Space, Form, Button, Typography, Alert } from 'antd';
import LoadingSpinner from '../LoadingSpinner';
import WmsConsumer from '../../models/WmsConsumer';
import WmsConsumerFormFields from './WmsConsumerFormFields';
import { Link } from "react-router-dom";
import { createWmsConsumer } from '../../api/wms-consumers';

const { Title, Text } = Typography;

const newWmsConsumer : WmsConsumer = {
    name: '',
    description: '',
    username: '',
    accessEnabled: true
};

const CreateWmsConsumer = () => {
    const [ isSaving, setIsSaving ] = useState(false);
    const [ savedWmsConsumer, setSavedWmsConsumer ] = useState<WmsConsumer | undefined>(undefined);
    
    const handleSubmit = async (wmsConsumer : WmsConsumer) => {
        setIsSaving(true);
        setSavedWmsConsumer(await createWmsConsumer(wmsConsumer));
        setIsSaving(false);
    };

    if(isSaving) {
        return <LoadingSpinner/>;
    }

    return (
        <Space direction="vertical">
            <Title level={2}>Create WMS consumer</Title>
            {
                savedWmsConsumer &&
                (
                    <>
                        <Alert
                            type="success"
                            message="The WMS consumer was created."
                            showIcon
                        />
                        <dl>
                            <dt>Username</dt>
                            <dd>{savedWmsConsumer.username}</dd>

                            <dt>Password</dt>
                            <dd>
                                <Text copyable>{savedWmsConsumer.password}</Text>
                            </dd>
                        </dl>
                        <Link to="/admin/wms-consumers">
                            <Button type="default">
                                OK
                            </Button>
                        </Link>
                    </>
                )
            }
            {
                !savedWmsConsumer &&
                (
                    <Form
                        labelCol={{ span: 8 }}
                        style={{ maxWidth: 600 }}            
                        initialValues={newWmsConsumer}
                        onFinish={handleSubmit}
                        autoComplete="off"
                    >
                        <WmsConsumerFormFields />
        
                        <Form.Item wrapperCol={{ offset: 8 }}> 
                            <Space>
                                <Button type="primary" htmlType="submit">
                                    Create
                                </Button>
                                <Link to="/admin/wms-consumers">
                                    <Button type="default">
                                        Cancel
                                    </Button>
                                </Link>
                            </Space>
                        </Form.Item>
                    </Form>
                )
            }
        </Space>
    );
};

export default CreateWmsConsumer;
