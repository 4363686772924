import React from 'react';
import { createBrowserRouter, createRoutesFromElements, RouterProvider, Route } from "react-router-dom";
import Map from './Map';
import Admin from './admin/Admin';
import WmsConsumers from './admin/wms-consumers/WmsConsumers';
import CreateWmsConsumer from './admin/wms-consumers/CreateWmsConsumer';
import EditWmsConsumer from './admin/wms-consumers/EditWmsConsumer';
import WmsGuide from './WmsGuide';

import 'antd/dist/reset.css';
import './App.css';

const App = () => {
    return (
        <RouterProvider
            router={createBrowserRouter(createRoutesFromElements(
                <>
                    <Route path="/admin" Component={Admin}>
                        <Route path="wms-consumers" Component={WmsConsumers} />
                        <Route path="create-wms-consumer" Component={CreateWmsConsumer} />
                        <Route path="wms-consumers/:username" Component={EditWmsConsumer} />
                    </Route>
                    <Route path="/wms-guide" Component={WmsGuide}/>
                    <Route path="/" Component={Map}/>
                </>
            ))}
        />
    );
};

export default App;
