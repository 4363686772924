import React, { ReactNode } from 'react';
import { Modal, Space, Button } from 'antd';

type ConfirmationModalProps = {
    open: boolean;
    title: string;
    body: string | ReactNode;
    confirmLabel: string;
    cancelLabel: string;
    type: string | undefined;
    onConfirm: () => void;
    onCancel: () => void;
};

const ConfirmationModal = ({ open, title, body, confirmLabel, cancelLabel, type, onConfirm, onCancel } : ConfirmationModalProps) => {
    return (
        <Modal
            title={title}
            open={open}
            onCancel={onCancel}
            footer={
                <Space>
                    <Button
                        type="primary"
                        danger={type === "danger"}
                        onClick={onConfirm}
                    >
                        {confirmLabel}
                    </Button>
                    <Button
                        onClick={onCancel}
                    >
                        {cancelLabel}
                    </Button>
                </Space>
            }
        >
            {body}
        </Modal>
    );
};

export default ConfirmationModal;
