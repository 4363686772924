import L, { Map } from 'leaflet';
import LayerGroup from '../models/LayerGroup';
import KeyedLeafletLayer from '../models/KeyedLeafletLayer';
import Proj from 'proj4leaflet';
import { tileLayerWithHeaders } from './TileLayerWithHeaders';
import 'leaflet-wms-header';
import queryString from 'query-string';
import { getResource } from '../resources/resource-manager';
import { getApiBaseUrl } from "../api/api-settings";

const leafletImage = require('leaflet-image');
require('leaflet-measure-ext/dist/leaflet-measure.js');
require('./limit-zoom.js');
require('./continuous-zoom.js');

export const globalMaximumZoomLevel : number = 20;

export const createLeafletLayer = (layerGroup : LayerGroup) : KeyedLeafletLayer =>
    layerGroup.type.toLowerCase() === 'wms'
        ? createLeafletWmsLayer(layerGroup)
        : createLeafletWmtsLayer(layerGroup);

const createLeafletWmtsLayer = (layerGroup : LayerGroup) : KeyedLeafletLayer => {
    const urlTemplate = `https://stskogsmonitor.blob.core.windows.net/tiles/${layerGroup.guid}/{z}/{x}/{y}.png`;
    const tileLayerOptions = {
        minZoom: layerGroup.minimumZoomLevel,
        maxZoom: globalMaximumZoomLevel,
        opacity: layerGroup.opacity,
        transparency: 'true',
        zIndex: layerGroup.zIndex,
        nativeZooms: createNativeZooms(layerGroup),
        className: `layer-group-${layerGroup.key}`
    };

    return {
        key: layerGroup.key,
        layer: L.tileLayer(urlTemplate, tileLayerOptions)
    };
};

const createLeafletWmsLayer = (layerGroup : LayerGroup) : KeyedLeafletLayer => {
    const tileLayerOptions = {
        minZoom: layerGroup.minimumZoomLevel,
        maxZoom: globalMaximumZoomLevel,
        opacity: layerGroup.opacity,
        transparency: 'true',
        zIndex: layerGroup.zIndex,
        nativeZooms: createNativeZoomsForInterval(0, globalMaximumZoomLevel),
        attribution: getResource(`attributions.${layerGroup.key}`, true /* returnUndefinedIfNotFound */),
        ...queryString.parse(layerGroup.wmsParameters!)
    };

    return {
        key: layerGroup.key,
        layer: L.tileLayer.wms(layerGroup.wmsUrl!, tileLayerOptions)
    };
};

export const createCrs = () : any => new (Proj as any).CRS(
    'EPSG:3857', // Web Mercator
    '+proj=merc +a=6378137 +b=6378137 +lat_ts=0 +lon_0=0 +x_0=0 +y_0=0 +k=1 +units=m +nadgrids=@null +wktext +no_defs +type=crs',
    {
        resolutions: getResolutions(),
        origin: [-20037508.342789, 20037508.342789], // upper left (NW) corner of Web Mercator
        bounds: L.bounds([-20037508.342789, -20037508.342789], [20037508.342789, 20037508.342789]) // bounding box for the map: Sweden and surroundings
        // bounds: L.bounds([1116534, 7354107], [2690592, 10772565]) // bounding box for the map: Sweden and surroundings
    }
);

const getResolutions = () : number[] =>  {
    const resolutions : number[] = [];
    for(let zoom = 0 ; zoom <= globalMaximumZoomLevel; zoom++) {
        resolutions.push(156543.0339280410 / Math.pow(2, zoom));
    }
    return resolutions;
};

export const createGoogleSatelliteLayer = () : KeyedLeafletLayer => ({
    key: 'GoogleSatellite',
    layer: L.tileLayer('https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}', {
        minZoom: 0,
        maxZoom: globalMaximumZoomLevel,
        opacity: 1,
        zIndex: 1,
        attribution: getResource('attributions.GoogleSatellite'),
        nativeZooms: createNativeZoomsForInterval(0, globalMaximumZoomLevel)
    } as any)
});

export const createLantmäterietTopowebbLayer = (accessToken: string) : KeyedLeafletLayer => ({
    key: 'LantmäterietTopowebb',
    layer: tileLayerWithHeaders(
        'https://api.lantmateriet.se/open/topowebb-ccby/v1/wmts/1.0.0/topowebb/default/3857/{z}/{y}/{x}.png',
        {
            minZoom: 0,
            maxZoom: globalMaximumZoomLevel,
            opacity: 1,
            zIndex: 0,
            nativeZooms: createNativeZoomsForInterval(0, 14),
            attribution: getResource('attributions.LantmäterietTopowebb')
        },
        [
            { header: 'Authorization', value: `Bearer ${accessToken}` }
        ]
    ),
    exportableLayerCreator: createExportableLantmäterietTopowebbLayer
});

const createExportableLantmäterietTopowebbLayer = () : KeyedLeafletLayer => ({
    key: 'ExportableLantmäterietTopowebb',
    layer: L.tileLayer(
        `${getApiBaseUrl()}/lantmateriet/topowebb/tiles?z={z}&y={y}&x={x}`, {
            minZoom: 0,
            maxZoom: globalMaximumZoomLevel,
            opacity: 1,
            zIndex: 0,
            nativeZooms: createNativeZoomsForInterval(0, 14),
            attribution: getResource('attributions.LantmäterietTopowebb')
        } as any
    )
});

export const createLantmäterietHistoricOrtophotoLayer = (key: string, wmsLayer: string, accessToken: string) : KeyedLeafletLayer => ({
    key,
    layer: (L.TileLayer.wmsHeader as any)(
        'https://api.lantmateriet.se/historiska-ortofoton/wms/v1', 
        {
            layers: wmsLayer,
            transparent: true, 
            format: 'image/png', 
            opacity: 1,
            zIndex: 2,
            nativeZooms: createNativeZoomsForInterval(0, 14),
            attribution: getResource(`attributions.${key}`)
        },
        [
            { header: 'Authorization', value: `Bearer ${accessToken}` }
        ]
    ),
    exportableLayerCreator: () => createExportableLantmäterietHistoricOrtophotoLayer(key, wmsLayer)
});

const createExportableLantmäterietHistoricOrtophotoLayer = (key: string, wmsLayer: string) : KeyedLeafletLayer => ({
    key,
    layer: (L.TileLayer.wmsHeader as any)(
        `${getApiBaseUrl()}/lantmateriet/historic-orthophotos/wms`,
        {
            layers: wmsLayer,
            transparent: true, 
            format: 'image/png', 
            opacity: 1,
            zIndex: 2,
            nativeZooms: createNativeZoomsForInterval(0, 14),
            attribution: getResource(`attributions.${key}`)
        }
    )
});

// https://www.npmjs.com/package/leaflet-measure-ext
export const createLeafletMeasureOptions = () => {
    const labels : any = {};
    (['measure', 'measureDistancesAndAreas', 'createNewMeasurement', 'startCreating', 'finishMeasurement', 'lastPoint', 'area', 'perimeter', 'pointLocation', 'areaMeasurement', 'linearMeasurement', 'pathDistance', 'centerOnArea', 'centerOnLine', 'centerOnLocation', 'cancel', 'delete', 'kilometers', 'hectares', 'meters', 'decPoint', 'thousandsSep'] as string[])
        .forEach(item => {
            labels[item] = getResource(`leafletMeasure.${item}`);
        });

    return {
        position: 'topright',
        primaryLengthUnit: 'meters',
        primaryAreaUnit: 'hectares',
        secondaryLengthUnit: undefined,
        secondaryAreaUnit: undefined,
        units: {
            meters: {
                factor: 1,
                display: 'meters',
                decimals: 0
            },
            hectares: {
                factor: 0.0001,
                display: 'hectares',
                decimals: 1
            }
        },
        labels
    };
};

export const getLeafletCanvasLayerAsDataUrl = (map : Map, callback : (imageData : string) => void) : void => {
    const layersToHide : L.Layer[] = [];
    map.eachLayer(layer => {
        const l = layer as any;
        if(l._url) {
            layersToHide.push(l);
        }
    });
    layersToHide.forEach(layer => {
        map.removeLayer(layer);
    });
    
    leafletImage(
        map, 
        (err : any, canvas : any) => {
            callback(canvas.toDataURL('image/png'));
            layersToHide.forEach(layer => {
                map.addLayer(layer);
            });
        });
};

const createNativeZooms = (layerGroup : LayerGroup) : number[] => createNativeZoomsForInterval(layerGroup.minimumZoomLevel, layerGroup.maximumZoomLevel);

const createNativeZoomsForInterval = (minimumZoomLevel : number, maximumZoomLevel : number) : number[] => {
    const nativeZooms : number[] = [];
    for (let i = minimumZoomLevel; i <= maximumZoomLevel; i++) {
        nativeZooms.push(i);
    }
    return nativeZooms;
};

// fix for leaflet-measure for leaflet 1.8.0
// https://github.com/ljagis/leaflet-measure/issues/171#issuecomment-1137483548
(L.Control as any).Measure.include({
	// set icon on the capture marker
	_setCaptureMarkerIcon: function () {
		// disable autopan
		this._captureMarker.options.autoPanOnFocus = false;

		// default function
		this._captureMarker.setIcon(
			L.divIcon({
				iconSize: this._map.getSize().multiplyBy(2)
			})
		);
	},
});
