import React from 'react';
import format from 'format-number';
import { getResource } from '../resources/resource-manager';

type GeographicalPositionProps = {
    position: L.LatLng | undefined,
    numberOfDecimals?: number
};

const GeographicalPosition = ({ position, numberOfDecimals = 5 } : GeographicalPositionProps) => {
    if(!position) {
        return null;
    }
    const formatter = format({
        integerSeparator: getResource('thousandsSeparator'),
        decimal: getResource('decimalPoint'),
        truncate: numberOfDecimals
    });

    return (
        <span className="mobile-hidden">
            {formatter(position.lat)} {formatter(position.lng)}
        </span>
    );
};

export default GeographicalPosition;
