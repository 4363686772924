export interface GeolocationInitializationResult {
    supported: boolean;
    watchPositionHandle?: number;
};

export const initializeGeolocation = (
        onCurrentPositionSuccess : PositionCallback, 
        onCurrentPositionError : PositionErrorCallback,
        onWatchPositionSuccess : PositionCallback, 
        onWatchPositionError : PositionErrorCallback,
) : GeolocationInitializationResult => {
    const options = { enableHighAccuracy: true };
    const result : GeolocationInitializationResult = {
        supported: !!navigator.geolocation
    };
    if (result.supported) {
        navigator.geolocation.getCurrentPosition(onCurrentPositionSuccess, onCurrentPositionError, options);
        result.watchPositionHandle = navigator.geolocation.watchPosition(onWatchPositionSuccess, onWatchPositionError, options);
    }

    return result;
};