import React from 'react';
import { Space, Button, Form, Input, Alert, Typography } from 'antd';
import type { FormProps } from 'antd';

type FieldType = {
    password: string;
};

type PasswordFormProps = {
    isInvalid: boolean,
    onSubmit: (password: string) => void;
};

const { Title } = Typography;

const PasswordForm = ({ isInvalid, onSubmit } : PasswordFormProps) => {
    const onFinish: FormProps<FieldType>['onFinish'] = values => {
        onSubmit(values.password);
    };

    return (
        <Space direction="vertical">
            <Title level={2}>Log in to administration section</Title>
            {
                isInvalid &&
                (
                    <Alert
                        message="Invalid password."
                        type="error"
                        showIcon
                    />
                )
            }

            <Form
                onFinish={onFinish}
                layout="vertical"
                autoComplete="off"
            >
                <Form.Item<FieldType>
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: 'Please input your password.' }]}
                >
                    <Input.Password />
                </Form.Item>
            
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Space>
    );
};

export default PasswordForm;
