import L from 'leaflet';

L.Control.Spinner = L.Control.extend({
    onAdd: function () {
        const container = L.DomUtil.create('div');
        container.className = 'spinner';
        container.style.display = 'none';
        container.innerHTML= '<svg width="30" height="30" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><style>.leaflet-spinner{fill:white;stroke:rgba(0,0,0,0.33);stroke-width:1px;transform-origin:center;animation:leaflet_spinner_animation .75s infinite linear}@keyframes leaflet_spinner_animation{100%{transform:rotate(360deg)}}</style><path d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z" class="leaflet-spinner"/></svg>';
        return container;
    },

    onRemove: function () {
    },

    setVisible: function (value) {
        const container = this.getContainer();
        container.style.display = value ? 'block' : 'none';
    }
});

L.control.spinner = function (options) {
    return new L.Control.Spinner(options);
};
