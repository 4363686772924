import en from "./locales/en";
import sv from "./locales/sv";

export const languages: Language[] = ["en", "sv"];

/**
 * Localization for Leaflet.draw, changing between languages is now effortless.
 *
 * @param {string} [language='en'] Language to localize Leaflet.draw
 *
 * @example
 * import L from 'leaflet'
 * import drawLocales from 'leaflet-draw-locales'
 *
 * // Automatically defines Leaflet.draw locale
 * drawLocales('fr')
 *
 * // Customize locale language
 * const locale = drawLocales('fr')
 * locale.draw.toolbar.buttons.polygon = 'Awesome polygon!'
 * L.drawLocal = locale
 */
export const drawLocales = (language: Language): DrawLocal => {
  let locale = en;

  switch (language.toLocaleLowerCase()) {
    case "en":
    case "en-us":
    case "en-ca":
    case "en-gb":
    case "en-us.utf-8":
    case "english": {
      locale = en;
      break;
    }
    case "sv":
    case "sv-SE":
    case "sv-se":
    case "swedish":
        locale = sv;
      break;
    default:
      throw new Error("[language] not found");
  }
  return locale;
};

export default drawLocales;

export type Language =
  | "en" | "en-us" | "en-us.utf-8" | "english" | "en-ca" | "en-gb"
  | "sv" | "sv-se" | "sv-SE" | "swedish";

export interface DrawLocal {
  draw: Draw;
  edit: Edit;
}

export interface Draw {
  toolbar: DrawToolbar;
  handlers: DrawHandlers;
}

export interface Edit {
  toolbar: EditToolbar;
  handlers: EditHandlers;
}

export interface Action {
  title: string;
  text: string;
}

export interface DrawToolbar {
  actions: Action;
  finish: Action;
  undo: Action;
  buttons: {
    polyline: string
    polygon: string
    rectangle: string
    circle: string
    marker: string
    circlemarker: string,
    label: string
  };
}

export interface Tooltip {
  start?: string;
  cont?: string;
  end?: string;
}

export interface DrawHandlers {
  circle: {
    tooltip: {
      start: string,
    }
    radius: string,
  };
  circlemarker: {
    tooltip: {
      start: string,
    },
  };
  label: {
    tooltip: {
      start: string,
    },
  };
  marker: {
    tooltip: {
      start: string,
    },
  };
  polygon: {
    tooltip: {
      start: string,
      cont: string,
      end: string,
    },
  };
  polyline: {
    error: string
    tooltip: {
      start: string,
      cont: string,
      end: string,
    },
  };
  rectangle: {
    tooltip: {
      start: string,
    },
  };
  simpleshape: {
    tooltip: {
      end: string,
    },
  };
}

export interface EditToolbar {
  actions: {
    save: Action
    cancel: Action
    clearAll: Action,
  };
  buttons: {
    edit: string
    editDisabled: string
    remove: string
    removeDisabled: string,
  };
}

export interface EditHandlers {
  edit: {
    tooltip: {
      text: string
      subtext: string,
    },
  };
  remove: {
    tooltip: {
      text: string,
    },
  };
}
