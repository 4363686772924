import { getApiBaseUrl } from "./api-settings";

export const validateAdminPassword = async (password : string) : Promise<boolean> => {
    const response = await fetch(
        `${getApiBaseUrl()}/authentication/validate-admin-password`,
        {
            method: "PUT", 
            body: JSON.stringify({ password }), 
            headers: { "Content-Type": "application/json" }
        }
    );
    return response.status === 200;
};