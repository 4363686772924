import React, { useEffect, useState } from 'react';
import type { MenuProps } from 'antd';
import { Layout, Menu, theme } from 'antd';
import { Outlet, useLocation } from "react-router-dom";
import PasswordForm from './PasswordForm';
import LoadingSpinner from './LoadingSpinner';
import { validateAdminPassword } from '../api/authentication';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import store from 'store2';

import './Admin.css';

type MenuItem = Required<MenuProps>['items'][number];

type UrlMenuItem = MenuItem & { url?: string };

const { Content, Sider } = Layout;
const adminPasswordKey = 'adminPassword';

const Admin = () => {
    const { token: { borderRadiusLG } } = theme.useToken();
    const location = useLocation();
      
    const [ passwordIsValid, setPasswordIsValid ] = useState(false);
    const [ passwordSubmitted, setPasswordSubmitted ] = useState(false);
    const [ isValidatingPasswordNow, setIsValidatingPasswordNow ] = useState(false);
    const [ password, setPassword ] = useState<string | undefined>(store(adminPasswordKey));

    useEffect(() => {
        if(password) {
            setIsValidatingPasswordNow(true);
            const fetchData = async () => {
                const isValid = await validateAdminPassword(password);
                if(!isValid) {
                    store.remove(adminPasswordKey);
                }
                setPasswordIsValid(isValid);
                setIsValidatingPasswordNow(false);
            };
    
            fetchData();
        }
    }, [password]);

    const menuItems : UrlMenuItem[] = passwordIsValid
        ? [
            {
                icon: <FontAwesomeIcon icon={faUsers} />,
                key: 'WmsConsumers',
                label: <Link to="wms-consumers">WMS consumers</Link>,
                url: 'wms-consumers'
            },
            {
                icon: <FontAwesomeIcon icon={faRightFromBracket} />,
                key: 'LogOut',
                label: 'Log out',
                onClick: () => {
                    store.remove(adminPasswordKey);
                    setPassword(undefined);
                    setPasswordIsValid(false);
                    setPasswordSubmitted(false);
                }
            }
        ]
        : [];

    const menuSelectedKeys = menuItems
        .filter(o => o.url && location.pathname === `/admin/${o.url}`)
        .map(o => o.key as string);

    const handlePasswordSubmit = (pw: string) => {
        setIsValidatingPasswordNow(true);
        store(adminPasswordKey, pw);
        setPassword(pw);
        setPasswordSubmitted(true);
    };

    return (
        <Layout>
            <Sider
                breakpoint="sm"
                collapsedWidth="0"
            >
                <Link to="">
                    <div id="admin-logotype">
                        <img id="logotype" src="/assets/skogsmonitor.png" alt="Skogsmonitor" />
                        <div id="admin-text">Administration</div>
                    </div>
                </Link>
                <Menu theme="dark" mode="inline" items={menuItems} selectedKeys={menuSelectedKeys} />
            </Sider>
            <Content 
                style={{
                        padding: 24,
                        minHeight: '100vh',
                        borderRadius: borderRadiusLG,
                        maxWidth: '800px',
                        margin: '0 auto'
                    }}
            >
                {
                    isValidatingPasswordNow && <LoadingSpinner/>
                }
                {
                    !isValidatingPasswordNow && !passwordIsValid && <PasswordForm isInvalid={!passwordIsValid && passwordSubmitted} onSubmit={handlePasswordSubmit} />
                }
                {
                    passwordIsValid && <Outlet/>
                }
            </Content>
        </Layout>
    );
};

export default Admin;
