import React from 'react';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type ToolbarButtonProps = {
    icon: any;
    mobileOnly?: boolean;
    tooltip?: string;
    onClick?: () => void;
};

const ToolbarButton = ({ icon, mobileOnly = false, tooltip, onClick } : ToolbarButtonProps) => {
    return (
        <Button size="small" onClick={onClick} className={`toolbar-button${mobileOnly ? ' mobile-only' : ''}`} icon={<FontAwesomeIcon icon={icon} />} title={tooltip} />
    );
};

export default ToolbarButton;
